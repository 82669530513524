
  import AccountingComponent from '~/components/accounting/Invoice'
  import { accountingTabs } from '~/data-structures/accounting'

  export default {
    name: `AccountingPage`,
    components: { AccountingComponent },
    middleware({ $gates, store, route, redirect }) {
      const isMaster = store.getters[`permissions/userIsMaster`]
      if (!isMaster) {
        redirect(`/`)
      }
    },
    data() {
      return {
        tabs: accountingTabs()
      }
    },
    computed: {
      selectedTab: {
        get() {
          return this.$route.hash || `#invoices`
        },
        set(value) {
          this.value = value
        }
      },
      currentTab() {
        const currentTab = this.tabs[this.selectedTab]
        if (!currentTab) {
          throw new Error(`Tab is not recognized!`)
        }
        return { ...currentTab }
      }
    },
    mounted() {},
    methods: {}
  }
